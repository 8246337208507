
.list_content_harflar{
   display: flex;
   justify-content: flex-end;
   align-items: center;
   width: 100%;
}
.content_inner_harflar{
    padding-right: 4px;
    cursor: pointer;
}
.activeBtn_harflar {
    box-shadow: 1px 1px 16px -1px rgba(0,185,120,0.78);
    background-color: #065637;
    color: #fff;
}

.not-active_harflar{
    box-shadow: 1px 1px 21px 0px rgba(196,194,196,1);
    background-color: white;
    color: #065637;
}
 @media screen and (max-width: 1500px) {
    .react-player-harflar{
        height: 34em;
        width: 60em;
    }
    }
    @media screen and (max-width: 600px) {
        .react-player-harflar{
            height: 100% !important;
            width: 100% !important;
        }
        }