.zikrlar_main_div{
    justify-content: center;
}
.zikr_main_nav_div{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.zikr_main_button{
    margin: 1%;
    border-radius: 8px;
    border: none;
    height: 45px;
    width: 13%;
    color: #065637;
    font-weight: bold; 
}
.zikr_list_title_div{
    -webkit-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
    -moz-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
    box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
}
.zikr_list_title {
    display: flex;
    align-items: center;
    font-size: 25px;
    color: #065637;
    font-weight: bold;
    }
    .logo_div2{
        position: absolute;
        display: flex;
        left: 50px;
        top: 23px;
        cursor: pointer;
        z-index: 10;
    }
    .alquran_logo2{
       height: 75px;
       width: 75px; 
       cursor: pointer;
    }
    .activeBtn {
        box-shadow: 1px 1px 16px -1px rgba(0,185,120,0.78);
        background-color: #065637;
        color: #fff;
    }
    .not-active{
        box-shadow: 1px 1px 21px 0px rgba(196,194,196,1);
        background-color: white;
        color: #065637;
    }
    @media screen and (max-width: 1500px) {
        .logo_div2{
            top: 20px;
        }
        .alquran_log2{
            width: 50px;
        }
        .zikr_list_title{
          font-size: 20px;
      }  
    }
    @media screen and (max-width: 600px) {
        .logo_div2{
            display: none;
        }
        .zikr_list_title_div h5{
        font-size: 16px;
        margin-top: 10px;  
        } 
        .zikr_list_title_div{
        box-shadow: none;
        } 
        .zikr_list_title {
            display: none
        }
        .zikr_main_button{
        margin: 1%;
        height: 30px;
        width: 10em;
        font-size: 11px;
        line-height: 13px;
        border: 1px solid #596673 ;  
        }
    }