.quronVideoList{
-webkit-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
-moz-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
}
.quran_main_div{
    justify-content: center;
}
.quran_main_nav_div{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.quran_list_title{
    padding: 20px;
}
.list_content_quron{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
 }
 .content_inner_quron{
     padding-right: 4px;
     cursor: pointer;
 }
.quran_list_title h5{
    font-size: 25px;
    color: #065637;
    font-weight: bold;
    }
    .quran_main_nav_div {
        color: #065637;
        font-weight: bold; 
        font-size: 30px;
        align-items: center;
    }
    .logo_div2{
        position: absolute;
        display: flex;
        left: 50px;
        top: 40px;
        cursor: pointer;
        z-index: 10;
    }
    .alquran_logo2{
       height: 60px;
       width: 60px; 
       cursor: pointer;
    }


    @media screen and (max-width: 1500px) {
        .react-player-quron{
            height: 34em;
            width: 60em;
        }
        .icon-quran{
            height: 50px;
        }

        }

        @media screen and (max-width: 600px) {
            .logo_div2{
                display: none;
            }
            .quran_list_title h5 {
            display: none;
            } 
         
            .quran_list_title_div{
            box-shadow: none;
            } 
            .quran_list_title{
                padding: 2px;
            }
            .react-player-quron{
                height: 100% !important;
                width: 100% !important;
            }
        }