.namoz_main_class {
    background: url(../../images/alQuranMainBack.svg) ;
    height: 100vh;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    padding-top: 70px;
}
.namoz_row0{
    justify-content: space-around;
    width: 100%;
}
.namoz_row0_left{
width: 100%;
display:flex;
margin-top: 10px;
margin-left: 40px;
flex-direction: column;
align-items: flex-start;

}
.namoz_row0_left h6{
font-size: 36px;
color: #065637;
}

.namoz_row0_right h4{
    color: #065637;
    font-weight: bolder;
    font-size: 40px;
    line-height: 5px;
}
.namoz_row0_right h3{
    color: #065637;
    font-weight: bold;
    font-size: 41px;
}
.namoz_row0_right h6{
    color: #065637;
    font-size: 25px;
}
.namoz_locationIcon{
    padding-left: 5px;
}
.namoz_am_pm{
    font-size: 25px;
}
.namoz_row0_right{
width: 100%;
display:flex;
margin-top: 10px;
margin-right: 40px;
flex-direction: column;
align-items: flex-end;
};
.namoz_second_row{
    display: flex;
    justify-content: flex-end;
}
.namoz_row1{
    justify-content: flex-end;
}
.namoz_row2 {
        justify-content: flex-end;
}
.namoz_table{
    width: 40%;
    margin: 20px;
}
.namozClass-active {
    border-bottom: 2px solid rgb(25, 25, 25, 0.2);
    background-color: #00B273;
}
.namozClass-inactive {
    border-bottom: 2px solid rgb(25, 25, 25, 0.2);
    background-color:white;
}
.namoz_text{
padding: 30px;
font-size: 25px;
}
@media screen and (max-width: 1500px) {
.namoz_text{
padding: 10px;
font-size: 20px;
}
.namoz_main_class {
 padding-top: 20px;
}
.namoz_row0_left h6{
    font-size: 26px;
    color: #065637;
}
.namoz_row0_left h3{
    font-size: 26px;
    color: #065637;
}
.namoz_am_pm{
    font-size: 20px;
}
}
@media screen and (max-width: 900px) {
    .namoz_table{
        width: 90%;
        margin: 10px;
        margin-top: 280px;
        margin-right:20px;
    }
    .namoz_text{
        font-size: 20px;
        padding: 10px;
    }
}
@media screen and (max-width: 812px) {
    .namoz_main_class {
        height: 112vh !important;
        width: 100%;
    }
    .namoz_table{
        position: absolute;
        top: 30%;
        left: 3%;
    }
}
@media screen and (max-width: 667px) {
    .namoz_main_class {
        height: 114vh !important;
    }
}
@media screen and (max-width: 600px) {
    .namoz_main_class {
       background: url(../../images//mainPagePhone.png) ;
       width: 100%;
       background-repeat:no-repeat;
       background-size:cover;
       background-position:center;
       /* padding-top: 20px; */
   } 
   .namoz_text{
    padding: 5px;
    }    
   .namoz_row0_left{
    margin-top: 5px;
    margin-left: 5px; 
   }
   .namoz_row0_left h6{
    font-size: 20px;
    color: #065637;
    }
   .namoz_row0_right{
    margin-top: 10px;
    margin-right: 10px;
    };
        .namoz_row0_left h6{
            font-size: 16px;
        }
        .namoz_row0_right h4{
            color: #065637;
            font-weight: bolder;
            font-size: 25px;
            line-height: 1px;
        }
        .namoz_row0_right h3{
            color: #065637;
            font-weight: bold;
            font-size: 25px;
        }
        .namoz_row0_right h6{
            color: #065637;
            font-size: 15px;
        }
        .namoz_locationIcon{
            padding-left: 5px;
            height: 12px;
        }
        .namoz_am_pm{
            font-size: 14px;
        }
      
   } 
