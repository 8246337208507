.videoList_harflar{
    -webkit-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
-moz-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
}
@media screen and (max-width: 1500px) {
.react-player-harflar{
    height: 34em;
    width: 60em;
}
}