.zikrlarVideoList{
    -webkit-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
-moz-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
}
.zikrlar_div{
    justify-content: center;

}
.zikrlar_nav_div{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.zikrlar_button_div{
    margin: 1%;
    border-radius: 8px;
    border: none;
    height: 45px;
    width: 13%;
    color: #065637;
    font-weight: bold;
}

.zikrlar_title_div h5{
font-size: 25px;
color: #065637;
font-weight: bold;
}
.list_content_tongi{
   display: flex;
   justify-content: flex-end;
   align-items: center;
   width: 100%;
}
.content_inner_tongi{
    padding-right: 4px;
    cursor: pointer;
}

@media screen and (max-width: 1500px) {
    .react-player-tongi{
        height: 34em;
        width: 60em;
    }
    .icon-tongi{
        height: 50px;
    }
    }
@media screen and (max-width: 600px) {
    .zikrlar_title_div h5{
        font-size: 16px;
        margin-top: 10px;
    }
    .zikrlar_title_div{
        box-shadow:none 
    }
    .zikrlar_button_div{
        margin: 1%;
        height: 30px;
        width: 10em;
        font-size: 11px;
        line-height: 13px;
        border: 1px solid #596673 ;
    }
    .react-player-tongi{
        height: 100% !important;
        width: 100% !important;
    }
 
}