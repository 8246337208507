.suralarVideoList{
    -webkit-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
-moz-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
}
.list_content_suralar{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
 }
 .content_inner_suralar{
     padding-right: 4px;
     cursor: pointer;
 }
 .suralar_list{
     font-size: 21px;
 }
 @media screen and (max-width: 1500px) {
    .react-player-suralar{
        height: 34em;
        width: 60em;
    }
    .suralar_list{
        font-size: 16px;
    }
    }
    @media screen and (max-width: 600px) {
        .react-player-suralar{
            height: 100% !important;
            width: 100% !important;
        }
        }