
.muallimiSoniy_div{
    justify-content: center;

}
.nav_div{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.button_div{
    margin: 1%;
    border-radius: 8px;
    border: none;
    height: 55px;
    width: 13%;
    color: #065637;
    font-weight: bold;
    font-size: 19px;
}

.title_div h5{
font-size: 25px;
color: #065637;
font-weight: bold;
}
.list_content{
   display: flex;
   justify-content: flex-end;
   align-items: center;
   width: 100%;
}
.content_inner{
    padding-right: 4px;
    cursor: pointer;
}
.listTitle{
    -webkit-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
    -moz-box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
    box-shadow: -21px 3px 13px 2px rgba(0,0,0,0.12);
}
.logo_div1{
    position: absolute;
    display: flex;
    left: 50px;
    top: 30px;
    width: 80px;
    cursor: pointer;
    z-index: 10;
    /* border: 1px solid red; */
}
.alquran_logo{
   height: 75px;
   width: 75px; 
   cursor: pointer;
}

.activeBtn {
    box-shadow: 1px 1px 16px -1px rgba(0,185,120,0.78);
    background-color: #065637;
    color: #fff;
    line-height: 30px;
}

.not-active{
    box-shadow: 1px 1px 21px 0px rgba(196,194,196,1);
    background-color: white;
    color: #065637; 
    line-height: 30px;   
}

@media screen and (max-width: 600px) {
    .logo_div1{
        display: none;
    }
    .title_div h5{
        font-size: 20px;
        margin-top: 10px;
    }
    .listTitle{
        box-shadow:none 
    }
    .button_div{
        margin: 1%;
        height: 30px;
        width: 10em;
        font-size: 11px;
        line-height: 13px;
        border: 1px solid #596673 ;
    }
    .activeBtn{
        font-size: 10px !important;
    }
    .not-active{
        font-size: 10px !important;
    }
}

@media screen and (max-width: 1500px) {
    .logo_div1{
        top: 20px;
    }
    .alquran_logo{
        width: 55px;
    }
    .activeBtn{
        font-size: 16px;
    }
    .not-active{
        font-size: 16px;
    }
}