.list_content_tungi{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
 }
 .content_inner_tungi{
     padding-right: 4px;
     cursor: pointer;
 }
 @media screen and (max-width: 1500px) {
    .react-player-tungi{
        height: 34em;
        width: 60em;
    }
    .icon-tungi{
        height: 50px;
    }
    }
    @media screen and (max-width: 600px) {
        .react-player-tungi{
            height: 100% !important;
            width: 100% !important;
        }
    }