.main_class {
    background: url(../images/alQuranMainBack.svg) ;
    height: 100vh;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    padding-top: 70px;
}
.row0{
    justify-content: space-around;
    width: 100%;

}
.row0_left{
width: 100%;
display:flex;
margin-top: 10px;
margin-left: 40px;
flex-direction: column;
align-items: flex-start;

}
.row0_left h6{
font-size: 34px;
color: #065637;
}
.row0_right h4{
    color: #065637;
    font-weight: bolder;
    font-size: 45px;
    line-height: 5px;
}
.row0_right h3{
    color: #065637;
    font-weight: bold;
    font-size: 76px;

}
.row0_right h6{
    color: #065637;
    font-size: 30px;
    line-height: 5px;

}
.locationIcon{
    padding-left: 5px;
}
.am_pm{
    font-size: 45px;
}
.row0_right{
width: 100%;
display:flex;
margin-top: 10px;
margin-right: 40px;
flex-direction: column;
align-items: flex-end;
};
.second_row{
    /* display: flex; */
    /* justify-content: flex-end; */

}
.row1{
    justify-content: flex-end;
    margin-right: 50px;
    /* margin-top: 120px; */
}
.row2 {
        justify-content: flex-end;
        margin-right: 50px;

}
.namoz_vaqtlari_div{
    cursor: pointer;
    transition: transform 1s;
}
.namoz_vaqtlari_div:hover{
    transform: scale(1.1);
}
.muallimi_soni_div{
    cursor: pointer;
    transition: transform 1s;
}
.muallimi_soni_div:hover{
    transform: scale(1.1);
}
.quron_div{
    cursor: pointer;
    transition: transform 1s;
}
.quron_div:hover{
    transform: scale(1.1);
}
.zikrlar_div{
    cursor: pointer;
    transition: transform 1s;
}
.zikrlar_div:hover{
    transform: scale(1.1);
}
.namozvaqtiBack{
    width: 100%;
}
.muallimisoniyBack{
    width: 100%;
}
.quronBack{
    width: 100%;
}
.zikrlarBack{
    width: 100%;
}
@media screen and (max-width: 1500px) {
.main_class{
 padding-top: 40px;
}
.row0_right h3{
 font-size: 50px;
}
.am_pm{
    font-size: 30px;
}
.row0_left h6 {
    font-size: 20px;
}
 .muallimisoniyBack {
 height: 200px;
 width: 300px;
 }  
 .quronBack {
 height: 200px;
 width: 300px;
 } 
.zikrlarBack {
height: 200px;
width: 300px;
} 
.namozvaqtiBack {
height: 200px;
width: 300px;
}  
}

@media screen and (max-width: 650px) {
    .row1{
        /* padding-top: 20px; */
    }
    .main_class {
       background: url(../images/mainPagePhone.png) ;
       height: 100vh;
       background-repeat:no-repeat;
       background-size:cover;
       background-position:center;
       padding-top: 20px;
   } 
   .namozvaqtiBack{
    width: 100%;
    height: auto;
}
.muallimisoniyBack{
    width: 100%;
    height: auto;
}
.quronBack{
    width: 100%;
    height: auto;
}
.zikrlarBack{
    width: 100%;
    height: auto;
}
   .row0_left{
    margin-top: 5px;
    margin-left: 5px; 
   }
   .row0_left h6{
    font-size: 20px;
    color: #065637;
    }
   .row0_right{
    margin-top: 10px;
    margin-right: 10px;
    };
        .row0_left h6{
            font-size: 16px;
        }
        .row0_right h4{
            color: #065637;
            font-weight: bolder;
            font-size: 25px;
            line-height: 1px;
        }
        .row0_right h3{
            color: #065637;
            font-weight: bold;
            font-size: 25px;
        }
        .row0_right h6{
            color: #065637;
            font-size: 15px;
        }
        .locationIcon{
            padding-left: 5px;
            height: 12px;
        }
        .am_pm{
            font-size: 14px;
        }
        .row1{
            margin-top: 280px;
            margin-right: 0;
        }
        .row2{
            margin-right: 0;
        }
   } 

